<template>
  <div class="invoiceList" v-loading="invoiceLoad">
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="数字证书业务" name="businessOrder">
        <div class="transactionList">
          <el-table ref="tableList1" :data="transInvoiceList" style="width: 100%;" @filter-change="filterTag" :filter-multiple='false'>
            <el-table-column prop="transNo" label="业务单号" min-width="210" show-overflow-tooltip></el-table-column>
            <el-table-column prop="transCreatedDate" label="申请时间" min-width="160"></el-table-column>
            <el-table-column prop="transName" label="业务单名称" min-width="150" show-overflow-tooltip></el-table-column>
            <el-table-column prop="transType" label="业务类型" min-width="120" :filters="transTypeOption" :column-key="'transType'" :filter-multiple='false'>
              <template slot-scope="scope">
                <span>
                  {{scope.row.transType |transactionType}}
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="status" label="业务单状态" min-width="120">
              <template slot-scope="scope">
                <span :class="'statusColor statusColor' + scope.row.status">{{scope.row.status | certTransStatus}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="payTotal" label="金额" min-width="120">
              <template slot-scope="scope">
                <span>{{'¥'+scope.row.payTotal||'- -'}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="status" fixed="right" label="操作" width="150">
              <template slot-scope="scope">
                <el-button v-if="!scope.row.hasApplied&&!scope.row.hasInvoice" @click="getInvoiceQrCode(scope.row.transId,undefined,scope.row.enablePlain,scope.row.enableVat)" type="text">申请开票
                </el-button>
                <el-button v-else-if="scope.row.hasApplied&&!scope.row.hasInvoice" @click="viewProgress(scope.row.transId,scope.row.enablePlain,scope.row.enableVat,scope.row.invoiceCategory)"
                  type="text">
                  查看开票进度</el-button>
                <span v-else></span>
                <el-button v-if="scope.row.hasInvoice&&scope.row.invoiceFilePath" @click="viewInvoice(scope.row.invoiceFilePath)" type="text">查看发票</el-button>
                <el-button v-if="scope.row.hasInvoice&&scope.row.invoiceFilePath" @click="downloadFile(scope.row.invoiceFilePath, `${scope.row.transName}-发票.pdf`)" type="text">下载发票</el-button>
                <span v-if="scope.row.hasInvoice&&!scope.row.invoiceFilePath">--</span>
                <span v-if="scope.row.hasInvoice&&!scope.row.invoiceFilePath">&nbsp;&nbsp;</span>
                <span v-if="scope.row.hasInvoice&&!scope.row.invoiceFilePath">--</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pager-list">
          <v-pagination :size="pageSize" :sum="Number(total)" @page="transPageChange" @current="transCurrentChange" :currentPage="pageIndex" />
        </div>
      </el-tab-pane>
      <!-- <el-tab-pane label="网络身份实名认证业务" name="orderForm">
        <div class="orderList">
          <el-table ref="tableList2" :data="invoiceList" style="width: 100%;" header-row-class-name="tableHeadTitle" @filter-change="filterTag" :filter-multiple='false'>
            <el-table-column prop="transNo" label="订单号" min-width="200"></el-table-column>
            <el-table-column prop="companyName" label="集成企业" min-width="180" show-overflow-tooltip></el-table-column>
            <el-table-column prop="productType" label="产品名称" min-width="140" show-overflow-tooltip>
              <template slot-scope="scope">
                <span>{{scope.row.productType|productType}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="productName" label="类型" column-key="productCode" :filters="productDescription" :filter-multiple='false' min-width="120"></el-table-column>
            <el-table-column prop="productSpecs" label="规格" column-key="specs" :filters="productNorms" :filter-multiple='false' min-width="100">
              <template slot-scope="scope">
                <span>{{scope.row.productSpecs|productSpecs}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="status" label="状态" :filter-multiple='false' min-width="100">
              <template slot-scope="scope">
                <span :class="'statusColor statusColor'+scope.row.status">{{scope.row.status|orderStatus}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="payTotal" label="订单金额" min-width="100">
              <template slot-scope="scope">
                <span>{{'¥'+scope.row.payTotal||'- -'}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="transCreatedDate" label="下单时间" min-width="160"></el-table-column>
            <el-table-column prop="createdDate" fixed="right" label="操作" width="150">
              <template slot-scope="scope">
                <el-button v-if="!scope.row.hasInvoice" @click="getInvoiceQrCode(scope.row.transNo,'order',true)" type="text">开具发票</el-button>
                <span v-else></span>
                <el-button v-if="scope.row.hasInvoice" @click="viewInvoice(scope.row.invoiceFilePath)" type="text">查看发票</el-button>
                <span v-else></span>
                <el-button v-if="scope.row.hasInvoice&&scope.row.invoiceFilePath" @click="downloadFile(scope.row.invoiceFilePath, `${scope.row.productName}-发票.pdf`)" type="text">下载发票</el-button>
                <span v-else></span>
              </template>
            </el-table-column>
          </el-table>
          <div class="pager-list">
            <v-pagination :size="pageSize" :sum="Number(total)" @page="pageChange" @current="currentChange" :currentPage="pageIndex" />
          </div>
        </div>
      </el-tab-pane> -->
      <!-- <el-tab-pane label="签名验签业务" name="signature">
        <div class="orderList">
          <el-table ref="tableList3" :data="reportlist" style="width: 100%;" header-row-class-name="tableHeadTitle" @filter-change="filterTag" :filter-multiple='false'>
            <el-table-column prop="transactionNo" label="业务单号" min-width="200"></el-table-column>
            <el-table-column prop="createdDate" label="申请时间" min-width="160"></el-table-column>
            <el-table-column prop="transactionName" label="业务单名称" show-overflow-tooltip min-width="180"></el-table-column>
            <el-table-column prop="transactionType" label="类型" min-width="100" column-key="transactionType" :filters="reportsType" :filter-multiple='false'>
              <template slot-scope="scope">
                <span>{{scope.row.transactionType===0?'电子版':'纸质版'}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="amount" label="金额" min-width="100">
              <template slot-scope="scope">
                <span>{{'¥'+scope.row.amount||'- -'}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="transactionStatus" label="业务单状态" min-width="100">
              <template slot-scope="scope">
                <span :class="'statusColor statusColor'+scope.row.transactionStatus">{{scope.row.transactionStatus|reportStatus}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="createdDate" label="操作" fixed="right" width="160">
              <template slot-scope="scope">
                <el-button v-if="!scope.row.hasInvoice" @click="getInvoiceQrCode(scope.row.transactionId,'report',true)" type="text">开具发票</el-button>
                <span v-else></span>
                <el-button v-if="scope.row.hasInvoice" @click="viewInvoice(scope.row.invoiceUrl)" type="text">查看发票</el-button>
                <span v-else></span>
                <el-button v-if="scope.row.hasInvoice&&scope.row.invoiceUrl" @click="downloadFile(scope.row.invoiceUrl, `${scope.row.transactionName}-发票.pdf`)" type="text">下载发票</el-button>
                <span v-else></span>
              </template>
            </el-table-column>
          </el-table>
          <div class="pager-list">
            <v-pagination :size="pageSize" :sum="Number(total)" @page="reportPageChange" @current="reportCurrentChange" :currentPage="pageIndex" />
          </div>
        </div>
      </el-tab-pane> -->
    </el-tabs>
    <v-invoiveImgShow ref="invoiveImgShow"></v-invoiveImgShow>
    <el-dialog title="查看发票" :visible.sync="isPDF" width="950px" center>
      <PdfPreview class="pdf-box" :url="pdfPreviewUrl"></PdfPreview>
    </el-dialog>
    <v-invoiveDetails ref="invoiveDetails" />
  </div>
</template>

<script>
import { reportStatus } from '@/utils/typeMatch'
import pagination from '@/components/pagination.vue'
import invoiveDetails from '../components/invoiveDetails.vue'
import invoiveImgShow from './../components/invoiveImgShow.vue'
import {
  transTypeOption,
  transStatusOption,
  productNorms
} from '@/utils/options'
import {
  productSpecs,
  productType,
  orderStatus,
  transactionType,
  certTransStatus
} from '@/utils/typeMatch.js'
export default {
  data() {
    return {
      activeName: 'businessOrder',
      invoiceLoad: false,
      transType: '',
      hasInvoice: false,
      businessType: 0,
      orderlist: [],
      pageIndex: 1,
      pageSize: 10,
      total: 0,
      pager: 0,
      transTypeOption: transTypeOption,
      transStatusOption: transStatusOption,
      productDescription: [],
      productNorms: productNorms,
      invoiceList: [],
      reportlist: [],
      transInvoiceList: [],
      pdfPreviewUrl: '',
      isPDF: false,
      options: {},
      reportsType: [
        {
          value: 0,
          text: '电子版'
        },
        {
          value: 1,
          text: '纸质版'
        }
      ]
    }
  },
  filters: {
    productSpecs,
    productType,
    orderStatus,
    transactionType,
    certTransStatus,
    reportStatus
  },
  components: {
    'v-pagination': pagination,
    'v-invoiveImgShow': invoiveImgShow,
    'v-invoiveDetails': invoiveDetails
  },
  mounted() {
    this.getProductCodeList()
    this.getTransInvoiceList()
  },
  methods: {
    clearFilter() {
      this.$refs.tableList1.clearFilter()
      // this.$refs.tableList2.clearFilter()
      // this.$refs.tableList3.clearFilter()
    },
    getProductCodeList() {
      this.$api.getCodeList({ productType: 0 }).then(res => {
        this.productDescription = res.data
      })
    },
    handleClick(tab) {
      this.pageIndex = 1
      this.pageSize = 10
      this.options = {}
      this.clearFilter()
      if (tab.name === 'orderForm') {
        this.getOrderInvoiceList()
      } else if (tab.name === 'businessOrder') {
        this.getTransInvoiceList()
      } else {
        this.getReportList()
      }
    },
    filterTag(value) {
      for (let key in value) {
        if (this.options[key] && this.options[key].length === 0) {
          this.options[key] = ''
        } else {
          this.options[key] = value[key].join(',')
        }
      }
      this.pageIndex = 1
      if (this.activeName === 'orderForm') {
        this.getOrderInvoiceList()
      } else if (this.activeName === 'businessOrder') {
        this.getTransInvoiceList()
      } else {
        this.getReportList()
      }
    },
    getTransInvoiceList() {
      let data = {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        hasInvoice: this.hasInvoice
      }
      for (let key in this.options) {
        data[key] = this.options[key]
      }
      this.$api.getInvoiceListPage(data).then(res => {
        this.isLoad = false
        this.total = res.data.totalCount
        this.transInvoiceList = res.data.list
      })
    },
    getOrderInvoiceList() {
      this.invoiceLoad = true
      let data = {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        hasInvoice: this.hasInvoice
      }
      for (let key in this.options) {
        data[key] = this.options[key]
      }
      this.$api
        .getOrderListPage(data)
        .then(res => {
          this.invoiceLoad = false
          this.invoiceList = res.data.list
          this.total = res.data.totalCount || 0
        })
        .catch(() => {
          this.invoiceLoad = false
        })
    },
    pageChange(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getOrderInvoiceList()
    },
    currentChange(val) {
      this.pageIndex = val
      this.getOrderInvoiceList()
    },
    transPageChange(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getTransInvoiceList()
    },
    transCurrentChange(val) {
      this.pageIndex = val
      this.getTransInvoiceList()
    },
    getInvoiceQrCode(transId, order, enablePlain, enableVat) {
      this.$refs.invoiveImgShow.showVat = enableVat
      this.$refs.invoiveImgShow.eInvoice = enablePlain
      if (!enablePlain && enableVat) {
        this.$refs.invoiveImgShow.infoIsShow = false
      }
      if (order !== undefined) {
        this.$refs.invoiveImgShow.getShow(transId, order)
      } else {
        this.$refs.invoiveImgShow.getShow(transId)
      }
    },
    downloadFile(url, name) {
      let xhr = new XMLHttpRequest()
      xhr.open('GET', url, true)
      xhr.responseType = 'blob'
      xhr.onload = () => {
        if (xhr.status === 200) {
          this.$util.downloadFile(xhr.response, name)
        } else {
          this.$message.error('发票下载失败')
        }
      }
      xhr.onerror = () => {
        this.$message.error('发票下载失败')
      }
      xhr.send()
    },
    viewInvoice(path) {
      this.pdfPreviewUrl = path
      this.isPDF = true
    },
    viewProgress(transId, enablePlain, enableVat, invoiceCategory) {
      this.$refs.invoiveDetails.transId = transId
      this.$refs.invoiveDetails.enablePlain = enablePlain
      this.$refs.invoiveDetails.enableVat = enableVat
      this.$refs.invoiveDetails.invoiceCategory = invoiceCategory
      this.$refs.invoiveDetails.viewStatus()
    },
    getReportList() {
      this.invoiceLoad = true
      let data = {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        hasInvoice: this.hasInvoice
      }
      for (let key in this.options) {
        data[key] = this.options[key]
      }
      this.$api
        .getReportInvoicePage(data)
        .then(res => {
          this.invoiceLoad = false
          this.reportlist = res.data.records
          this.total = res.data.total || 0
        })
        .catch(() => {
          this.invoiceLoad = false
        })
    },
    reportPageChange(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getReportList()
    },
    reportCurrentChange(val) {
      this.pageIndex = val
      this.getReportList()
    }
  }
}
</script>

<style lang="scss" scoped>
.invoiceList {
  padding: 20px 32px 78px;
  ::v-deep .el-tabs--card > .el-tabs__header {
    border: none;
  }
  ::v-deep .el-tabs__nav {
    border: none;
  }
  ::v-deep .el-tabs__item {
    height: 40px;
    line-height: 40px;
    padding: 0 16px;
    border-radius: 2px;
    border: 1px solid #dee0e9;
    margin-right: 24px;
  }
  ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item:first-child {
    border-left: 1px solid #dee0e9;
  }
  ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
    color: #1c67f3;
    background: #f1f5ff;
    border-radius: 2px;
    border: none;
  }
}
.transactionList {
  ::v-deep .el-table tr > th {
    background: #f7faff;
    padding: 2px 0;
    color: #6c737b;
    font-weight: normal;
    border-top: 1px solid #dee0e9;
    border-color: #dee0e9;
  }
  ::v-deep .el-table td {
    padding: 8px 0;
    border-color: #dee0e9;
  }
  ::v-deep .el-icon-arrow-down {
    width: 20px;
    height: 20px;
    vertical-align: middle;
    background: url('~@/assets/image/personCenter/select.png') no-repeat center;
    background-size: 16px;
    cursor: pointer;
  }
  .statusColor {
    height: 24px;
    line-height: 22px;
    display: inline-block;
    padding: 0 8px;
    border-radius: 15px;
    color: #52c41a;
    background: #eff9eb;
    border: 1px solid #d4f0c8;
  }
}
.orderList {
  padding-bottom: 72px;
  ::v-deep .el-table tr > th {
    background: #f7faff;
    padding: 2px 0;
    color: #6c737b;
    font-weight: normal;
    border-top: 1px solid #dee0e9;
    border-color: #dee0e9;
  }
  ::v-deep .el-table td {
    padding: 8px 0;
    border-color: #dee0e9;
  }
  ::v-deep .el-icon-arrow-down {
    width: 20px;
    height: 20px;
    vertical-align: middle;
    background: url('~@/assets/image/personCenter/select.png') no-repeat center;
    background-size: 16px;
    cursor: pointer;
  }
  .statusColor {
    height: 24px;
    line-height: 22px;
    display: inline-block;
    padding: 0 8px;
    border-radius: 15px;
    color: #52c41a;
    background: #eff9eb;
    border: 1px solid #d4f0c8;
  }
}
.pager-list {
  padding-top: 12px;
}
</style>