<template>
  <div class="commonBox">
    <sideBar :sideBarList="invoiceList" @changeMenu="changeMenu" />
    <div class="commonMainArea">
      <invoiceList ref="invoiceList" />
    </div>
  </div>
</template>

<script>
import { invoice } from '@/utils/options'
import invoiceList from './invoiceList.vue';
import sideBar from './../components/sideBar.vue'
export default {
  data () {
    return {
      invoiceList: invoice,
      activeName: 'businessOrder',
      showContent: 0
    }
  },
  components: {
    sideBar,
    invoiceList
  },
  methods: {
    handleClick () {
    },
    changeMenu (index) {
      this.showContent = index
      this.$refs.invoiceList.activeName = 'businessOrder'
      this.$refs.invoiceList.pageSize = 10
      this.$refs.invoiceList.pageIndex = 1
      this.$refs.invoiceList.options = {}
      this.$refs.invoiceList.clearFilter()
      if (!index) {
        this.$refs.invoiceList.hasInvoice = false
      } else {
        this.$refs.invoiceList.hasInvoice = true
      }
      this.$refs.invoiceList.getTransInvoiceList()
    },

  }
}
</script>

<style lang="scss" scoped>
</style>