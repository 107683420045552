<template>
  <div>
    <el-dialog title="申请详情" class="invoiveDetails" append-to-body :visible.sync="invoiveDetailsShow" width="578px">
      <div class="infoDetails">
        <div v-if="applyInfo.applyStatus==='1'|| applyInfo.applyStatus==='3' || applyInfo.failed == false">
          <h4><img src="@/assets/image/personCenter/accomplish.png">已申请，3-5个工作日内将发票发送至您预留的手机号或邮箱</h4>
          <!-- <h5>{{applyInfo.comment}}</h5> -->
        </div>
        <div v-if="applyInfo.applyStatus==='2'">
          <h3><img src="@/assets/image/personCenter/accomplish.png">发票申请成功</h3>
          <!-- <h2>{{applyInfo.comment}}</h2> -->
        </div>
        <div v-if="applyInfo.applyStatus==='1'||applyInfo.applyStatus==='3'">
          <p><span>发票类型</span>{{ '增值税专用发票（数电票）'}}</p>
          <p><span>付款账号</span>{{applyInfo.remitBankNo}}</p>
          <p><span>户名</span>{{applyInfo.remitName}}</p>
          <p><span>付款凭证</span>
            <el-button type="text" @click="voucherShow=true">{{applyInfo.remitName}}.{{applyInfo.voucherUrl.split('.').slice(-1).toString()}}</el-button>
          </p>
          <p><span>发票抬头</span>{{applyInfo.header}}</p>
          <p><span>公司税号</span>{{applyInfo.taxNo}}</p>
          <p><span>对公账号</span>{{applyInfo.acctNo}}</p>
          <p><span>开户行</span>{{applyInfo.bankBranch}}</p>
          <p><span>公司地址</span>{{applyInfo.companyAddress}}</p>
          <p><span>电话</span>{{applyInfo.phone}}</p>
          <p><span>手机号</span>{{applyInfo.telephone}}</p>
          <p><span>邮箱</span>{{applyInfo.email}}</p>
          <p><span>申请时间</span>{{applyInfo.createdDate}}</p>
          <hr>
          <p><span>业务名称</span>{{applyInfo.transName}}</p>
          <p><span>业务类型</span>{{applyInfo.transType}}</p>
          <p v-if="applyInfo.certDuration"><span>证书有效期</span>{{applyInfo.certDuration}}年</p>
          <p><span>金额</span>¥ {{applyInfo.amount}}</p>
        </div>
        <div v-if="applyInfo.failed == false">
          <p><span>发票类型</span>普通发票（数电票）</p>
          <p><span>发票抬头</span>{{applyInfo.invoiceTitle}}</p>
          <p><span>公司税号</span>{{applyInfo.taxNo}}</p>
          <p><span>手机号</span>{{applyInfo.phone}}</p>
          <p><span>邮箱</span>{{applyInfo.email}}</p>
          <p><span>申请时间</span>{{applyInfo.applyTime}}</p>
          <hr>
          <p><span>业务名称</span>{{applyInfo.transName}}</p>
          <p><span>业务类型</span>{{applyInfo.transType}}</p>
          <p v-if="applyInfo.certDuration"><span>证书有效期</span>{{applyInfo.certDuration}}年</p>
          <p><span>金额</span>¥ {{applyInfo.totalAmount}}</p>
        </div>
        <div v-if="applyInfo.applyStatus==='-1' || applyInfo.failed == true">
          <h3><img src="@/assets/image/personCenter/fill.png">发票申请失败</h3>
          <div v-if="!applyInfo.failReason && applyInfo.comment" class="failureReason"><span>失败原因：</span>{{applyInfo.comment}}</div>
          <div v-if="applyInfo.failReason" class="failureReason"><span>失败原因：</span>{{applyInfo.failReason}}</div>
          <el-button type="primary" size="medium" @click="reInvoicing">重新开票</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="查看付款凭证" :visible.sync="voucherShow" width="950px" center>
      <img class="paymentVoucher" :src="applyInfo.voucherUrl">
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      invoiveDetailsShow: false,
      voucherShow: false,
      transId: '',
      invoiceCategory: '',
      applyInfo: '',
      enablePlain: false,
      enableVat: false
    }
  },
  methods: {
    viewStatus() {
      let url = 'vatInvoiceViewStatus'
      if (this.invoiceCategory === 'P') {
        url = 'invoiceViewStatus'
      }
      this.$api[url]({ transId: this.transId }).then(res => {
        if (res.code === '0') {
          this.applyInfo = res.data
          this.invoiveDetailsShow = true
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    reInvoicing() {
      let url = 'vatInvoiceReapply'
      if (this.invoiceCategory === 'P') {
        url = 'invoiceReapply'
      }
      this.$api[url]({ transId: this.transId }).then(res => {
        if (res.code === '0') {
          this.invoiveDetailsShow = false
          this.$parent.getTransInvoiceList()
          this.$parent.getInvoiceQrCode(
            this.transId,
            undefined,
            this.enablePlain,
            this.enableVat
          )
        } else {
          this.$message.error(res.msg)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.invoiveDetails {
  ::v-deep .el-dialog {
    padding: 0 24px;
    border-radius: 4px;
    .el-dialog__header {
      padding: 0;
      border-bottom: 1px #dee2e8 solid;
      line-height: 44px;
      color: #2a2b2c;
      text-align: center;
      font-weight: 700;
      .el-dialog__title {
        font-size: 14px;
      }
      .el-dialog__headerbtn {
        top: 12px;
      }
    }
    .el-dialog__body {
      padding: 0;
    }
  }
}
.infoDetails {
  padding: 38px 16px 8px;
  h2 {
    font-size: 14px;
    color: #303b50;
    line-height: 19px;
    font-weight: normal;
    padding-bottom: 24px;
    border-bottom: 1px solid #dee2e8;
    margin-bottom: 24px;
  }
  h3 {
    font-size: 14px;
    color: #303b50;
    line-height: 20px;
    letter-spacing: 1px;
    text-align: center;
    margin-bottom: 17px;
    font-weight: normal;
  }
  h4 {
    font-size: 14px;
    color: #303b50;
    line-height: 19px;
    letter-spacing: 1px;
    text-align: center;
    margin-bottom: 24px;
    font-weight: normal;
  }
  img {
    width: 12px;
    margin-right: 8px;
  }
  h5 {
    font-size: 14px;
    color: #aeb5be;
    line-height: 19px;
    text-align: center;
    margin-bottom: 32px;
    font-weight: normal;
  }
  p {
    display: flex;
    font-size: 14px;
    color: #303b50;
    line-height: 19px;
    padding-bottom: 16px;
  }
  span {
    flex-shrink: 0;
    display: inline-block;
    width: 96px;
    color: #6c737b;
    letter-spacing: 1px;
  }
  hr {
    height: 1px;
    background: #dee2e8;
    border: none;
    margin: 4px 0 20px;
  }
  .el-button.el-button--text {
    padding: 0;
    white-space: break-spaces;
    text-align: left;
  }
  .el-button.el-button--primary {
    display: block;
    width: 130px;
    line-height: 14px;
    margin: 40px auto;
  }
}
.failureReason {
  font-size: 14px;
  color: #303b50;
  line-height: 19px;
  span {
    display: inline;
    color: #6c737b;
    letter-spacing: 1px;
  }
}
.paymentVoucher {
  width: 100%;
}
</style>